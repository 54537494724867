<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <b-container class="">
          <b-row class="">
            <b-col cols="12">
              <h1 class="sm">{{ isEditMode ? translations.tcEditConversation : translations.tcAddConversation }} &mdash;
                {{ conversation.selectedStateCountry.text }}</h1>
              <ConversationPageNavigation :i18n="translations.components" :page="currentPage">
              </ConversationPageNavigation>
              <hr />
            </b-col>
          </b-row>
        </b-container>
        <b-form @submit.prevent class="view-1 g-mb-2">
          <b-container>
            <b-row>
              <b-col cols="12">
                <div class="h-style-1">{{ translations.tcAttendees }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-container>
                  <b-row>
                    <b-col cols="12"> </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mr-5">
                      <div class="h-style-1">{{ translations.tcMembers }}</div>
                      <div class="member-select-find">
                        <b-form-select v-model="selectedMember" :options="availableMembers"
                          class="form-control g-select flex-0" style="display: inline;" @change="selectAMember($event)">
                          <template slot="first">
                            <option :value="null" disabled>{{ translations.tcAddMember }}</option>
                          </template>
                        </b-form-select>
                        <b-button :name="`memberFindButton`" variant="primary" class="btn-find"
                          @click="showModal($event, 'personSearchModal')">{{ translations.tcFind }}</b-button>
                      </div>
                      <div id="selectedMemberDiv" class="mt-3 ml-3 mb-3">
                        <div v-for="(member, index) in local_conversation.members" :key="index"
                          class="selectMemberOption">
                          <div class="memberName">{{ member.text }}</div>
                          <div @click="deleteMember(member)" class="deleteOption">
                            <ServiceDelete />
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- <b-col>
                      <div class="h-style-1">{{ translations.tcNonMembers }}</div>
                      <b-button
                        :name="`nonmemberAddButton`"
                        variant="primary"
                        class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'addNonMemberModal')"
                        >{{ translations.tcAddNonMember }}</b-button
                      >
                      <div id="selectedMemberDiv" class="mt-3 ml-3 mb-3">
                        <div
                          v-for="(nonmember, index) in local_conversation.nonmembers"
                          :key="index"
                          class="selectMemberOption"
                        >
                          <div class="memberName">{{ nonmember.text }}</div>
                          <div @click="deleteNonMember(nonmember)" class="deleteOption">
                            <ServiceDelete />
                          </div>
                        </div>
                      </div>
                    </b-col> -->
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="mt-3">
                      <b-button variant="primary" class="mr-3" @click="handleBackClick()">
                        {{ translations.tcBack }}
                      </b-button>
                      <b-button variant="primary" class="mr-3" @click="handleNextClick()">
                        {{ translations.tcNext }}
                      </b-button>
                      <b-button variant="tertiary" @click="handleCancelClick()">{{ translations.tcCancel }}</b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
        <section class="form-buttons"></section>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)" :camp_country="camp_country" :modal_title="modal_title"
      :use_org_not_camp="use_org_not_camp">
    </PersonSearch>
    <AddNonMemberModal @selectionMade="applyFoundNonMember($event)" :i18n="translations"
      :modal_title="nonmember_modal_title" :phoneRequired="phoneRequired" :emailRequired="emailRequired"
      :phoneOrEmailRequired="phoneOrEmailRequired" :cnt_key="camp_country || this.demograph.country_key">
    </AddNonMemberModal>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import ConversationPageNavigation from './conversation-page-navigation.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import AddNonMemberModal from './AddNonMemberModal.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { mustBeValid } from 'json-schema'

export default {
  name: 'add-conversation-attendees',
  mixins: [translationMixin],
  data() {
    return {
      activeNext: false,
      availableMembers: [],
      currentPage: 3,
      emailRequired: false,
      findButton: '',
      isEditMode: false,
      local_conversation: {
        members: [],
        nonmembers: [],
        members_remove: [],
        nonmembers_remove: []
      },
      modal_title: 'Individual Search',
      nonmember_modal_title: 'Add Non-Member',
      phoneRequired: false,
      phoneOrEmailRequired: false,
      selectedMember: null,
      selectedNonmember: null,
      translations: {},
      validateError: []
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('conversation-page-navigation', 'conversation-add-non-member')
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        //this.loadTranslations()
      })
      await this.page_load()
      this.availableMembers = [...this.options_members]
      await this.hydrateConversation()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      loadStateMembers: 'conversations/loadStateMembers',
      setLoadingStatus: 'menu/setLoadingStatus',
      updateConversationAttendees: 'conversations/updateConversationAttendees'
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        // const meetingTypePayload = {
        //   type: 'conversation',
        //   lang_key: this.demograph.language_key
        // }
        const mbt =
          this.conversation.eventType === constantData.ConversationMeetingTypes.TwoHourConversation
            ? '' // only load all members for two hour conversations
            : this.gideonMemberTypeKey
        const memberPayload = {
          org_key: this.officerToolbarSelected.country_state,
          lang_key: this.demograph.language_key,
          membertype_key: mbt
        }
        await Promise.all([this.loadStateMembers(memberPayload)]).then(() => { })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async applyFoundPerson(evt) {
      switch (this.findButton) {
        case 'memberFindButton':
          //check if selected individual is already in the local_conversation.members array
          if (this.local_conversation.members.length > 0) {
            let memberFind = this.local_conversation.members.findIndex(m => m.value === evt.ind_key)
            if (memberFind >= 0) {
              break
            }
          }
          this.local_conversation.members.push({
            value: evt.ind_key,
            text: evt.name_text
          })
          break
        case 'nonmemberFindButton':
          if (this.local_conversation.members.length > 0) {
            let nonmemberFind = this.local_conversation.nonmembers.findIndex(m => m.value === evt.ind_key)
            if (nonmemberFind >= 0) {
              break
            }
          }
          this.local_conversation.nonmembers.push({
            value: evt.ind_key,
            text: evt.name_text
          })
          break
        default:
          this.local_conversation.nonmembers.push({
            value: evt.ind_key,
            text: evt.name_text
          })
          break
      }
    },
    async applyFoundNonMember(evt) {
      this.local_conversation.nonmembers.push(evt)
    },
    deleteMember(member) {
      const index = this.local_conversation.members.findIndex(x => x.value === member.value)
      const selectedMember = this.local_conversation.members.find(x => x.value === member.value)
      if (index >= 0) {
        this.local_conversation.members.splice(index, 1)
        this.local_conversation.members_remove.push(selectedMember)
      }
    },
    deleteNonMember(nonmember) {
      const index = this.local_conversation.nonmembers.findIndex(x => x.value === nonmember.value)
      const selectedNonMember = this.local_conversation.nonmembers.find(x => x.value === nonmember.value)
      if (index >= 0) {
        this.local_conversation.nonmembers.splice(index, 1)
        this.local_conversation.nonmembers_remove.push(selectedNonMember)
      }
    },
    async handleBackClick() {
      const savedData = await this.saveDataToStore(false)
      if (savedData) {
        this.$router.push({ name: 'conversations-add-event-details' })
      }
    },
    handleCancelClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToCancel,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel
      }).then(result => {
        if (result.value) {
          this.clearConversation()
          this.$router.push({ name: 'conversations-landing-page' })
        }
      })
    },
    async handleNextClick() {
      const savedData = await this.saveDataToStore(true)
      if (savedData) {
        this.$router.push({ name: 'conversations-add-review' })
      }
    },
    async hydrateConversation() {
      try {
        this.setLoadingStatus(true)
        //get values from the store and populate local variables
        this.local_conversation.members = !!this.conversation.attendeesMembers
          ? JSON.parse(JSON.stringify(this.conversation.attendeesMembers))
          : []
        this.local_conversation.nonmembers = !!this.conversation.attendeesNonmembers
          ? JSON.parse(JSON.stringify(this.conversation.attendeesNonmembers))
          : []

        this.disableNext = false
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async saveDataToStore(validateForm = true) {
      if (validateForm) {
        this.validateEventDetails()

        if (this.validateError.length !== 0) {
          await this.$swal({
            icon: 'error',
            text: `${this.translations.tcErrorOneAttendee} '${this.validateError.join(': ')}'`,
            confirmButtonText: this.translations.tcOk || 'Ok'
          }).then(result => {
            return false
          })
          return false
        }
      }
      await this.updateConversationAttendees(this.local_conversation)
      return true
    },
    async selectAMember(evt) {
      try {
        this.setLoadingStatus(true)
        const member = this.state_members.find(x => x.vin_ind_key === evt)
        const memberOption = this.options_members.find(x => x.value === evt)
        //check if this.local_conversation_members contains a member with the same value
        let memberFind = -1
        if (this.local_conversation.members.length > 0) {
          memberFind = this.local_conversation.members.findIndex(m => m.value === evt)
        }
        if (memberFind < 0) {
          this.local_conversation.members.push(memberOption)
        }
        this.availableMembers = this.availableMembers.filter(x => x.value !== evt)
        this.selectedMember = null
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      switch (this.findButton) {
        case 'memberFindButton':
          this.modal_title = this.translations.tcMemberSearch
          break
        case 'nonmemberFindButton':
          this.modal_title = this.translations.tcNonMemberSearch
          break
        case 'nonmemberAddButton':
          this.modalAddNonMemberModal = this.translations.tcAddNonMember
          break
        default:
          this.modal_title = this.translations.tcIndividualSearch
          break
      }
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    validateEventDetails() {
      this.validateError.splice(0, this.validateError.length)

      // COMMENTED OUT BC NOT SURE IF THEY WILL BE COMING BACK
      // if (this.local_conversation.members.length === 0 && this.local_conversation.nonmembers.length === 0) {
      //   this.validateError.push(this.translations.tcErrorOneAttendee)
      // }

      if (this.validateError.length > 0) {
        this.errorMessage = `${this.translations.tcErrorOneAttendee} '${this.validateError.join(': ')}'`
      }
    }
  },
  computed: {
    ...mapGetters({
      conversation: 'conversations/conversation',
      demograph: 'user/demograph',
      officerToolbarSelected: 'user/officerToolbarSelected',
      officerToolbarCamps: 'user/officerToolbarCamps',
      otfbCountry: 'conversations/otfbCountry',
      prefCulture: 'user/userPreferredCulture',
      selectedConversationKey: 'user/userSelectedConversationKey',
      state_members: 'conversations/state_members',
      userCountryKey: 'user/userCountryKey'
    }),
    camp_country() {
      //this.otfbCountry.cnt_key is used if the user is an IO country
      if (Object.keys(this.otfbCountry).length > 0 && this.otfbCountry.cnt_key) {
        return this.otfbCountry.cnt_key
      }
      return null
    },
    allMemberTypeKey() {
      return constantData.member_types[constantData.member_types.map(m => m.text).indexOf('All')].value
    },
    gideonMemberTypeKey() {
      return constantData.member_types[constantData.member_types.map(m => m.text).indexOf('Gideon')].value
    },
    options_members() {
      return this.state_members.map(member => {
        return {
          value: member.vin_ind_key,
          text: member.vin_complete_name
        }
      })
    },
    use_org_not_camp() {
      //this.otfbCountry.cnt_key is used if the user is an IO country
      if (Object.keys(this.otfbCountry).length > 0 && this.otfbCountry.org_key) {
        return this.otfbCountry.org_key
      } else {
        //we need a camp so grab the first on in the officer toolbar list
        return this.officerToolbarCamps[0].value
      }
    }
  },
  watch: {
    // distributionSelected(newval, oldval) {
    //   if (newval.selected) {
    //     if (newval.key === 'full') {
    //       this.isSelectedAreas = false
    //       this.activeNext = false
    //     } else {
    //       this.isSelectedAreas = true
    //       this.activeNext = !this.selectedAreas.length > 0
    //     }
    //   }
  },
  components: {
    AddNonMemberModal,
    ConversationPageNavigation,
    pageBody,
    PersonSearch: PersonSearch,
    ServiceDelete: ServiceDelete
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.page-body {
  padding: 80px 15px;
}

.selectedMemberDiv {
  // display: flex;
  // flex-direction: row;
  // align-content: flex-start;
  // justify-content: left;
}

.selectMemberOption {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .memberName {
    display: block;
  }

  .deleteOption {
    cursor: pointer;
    display: inline-block;
  }
}

.member-select-find {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: left;

  .custom-select {
    width: 85%;
    max-width: 300px;
  }

  .btn-find {
    padding: 8px;
  }
}

.bg-block {
  padding: 24px;
}
</style>
